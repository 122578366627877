<template>
	<div class="aviation aviation-mt mt10">
		<div class="aviation-ba">
			<div class="aviation-jz">
				<!-- 保险列表最下改动  头部步骤条 -->
				<div class="aviation-jza">
					<div class="iconfont icon-baoxian2 aviation-jzc"></div>
					<div class="aviation-jzd">选择保险 Select</div>
				</div>
				<div class="aviation-jzb"></div>
				<div class="aviation-jza">
					<div class="iconfont icon-bianji2 aviation-jze aviation-jzee"></div>
					<div class="aviation-jzf">填写资料 Fill in</div>
				</div>
				<!-- <div class="aviation-jzb"></div>
				<div class="aviation-jza">
					<div class="iconfont icon-duigou aviation-jze"></div>
					<div class="aviation-jzf">确认资料 Confirm</div>
				</div> -->
				<div class="aviation-jzb"></div>
				<div class="aviation-jza">
					<div class="iconfont icon-xiangshang11 aviation-jze aviation-jzec"></div>
					<div class="aviation-jzf">提交订单 Submit</div>
				</div>
			</div>
		</div>
		<div class="aviation-bourn ">
			<!-- 重新搜索 -->
			<!-- <div class="aviation-bourn-a">
				<el-row type="flex" class="aviation-time" justify="space-between">
					<div>
						<div class="aviation-purpose">
							<div class="aviation-purpose-a">目的地：</div>
							<div class="aviation-purpose-b">
								{{ InsurSearchInfo.StartDate }}
								<i>至</i>
								{{ InsurSearchInfo.EndDate }}
								<i>{{ InsurSearchInfo.Days }}天</i>
								<i>保险公司：{{InsurSearchInfo.GoodOwnerCode}}</i>
							</div>
						</div>
					</div>
					<button class="aviation-seek" @click.stop="show3 = !show3">
						<span class="aviation-fangdaj iconfont icon-sousuo"></span>
						重新搜索
						<span class="el-icon-arrow-down" :class="{ arrowTransform: !show3, arrowTransformReturn: show3 }" @click.stop="show3 = !show3"></span>
					</button>
				</el-row>
			</div> -->

			<div class="transition-button">
				<!-- 重新搜索隐藏 -->
				<div style="margin-bottom: 20px;" class="transition-box">
					<div class="transition-text transition-md">
						<div class="transition-mdaa">目的地</div>
						<!-- <el-input
							size="medium"
							placeholder="中文/拼音/首字母/代码"
							v-model="InsurSearchInfo.DestinationCountry"
							@focus="onshowCity()"
							style="width:200px"
						></el-input> -->
						<el-autocomplete style="width:100%" size="" class="inline-input"
							v-model="DestinationCountryVal" :fetch-suggestions="queryCitySearch" placeholder="英文/中文/三字码"
							:trigger-on-focus="false" @select="handleSelectCity"></el-autocomplete>
						<span class="icon-fonta iconfont icon-didian"></span>
						<div class="mdd" v-if="showCity == true">
							<InsuranceCity @onhideCity="onhideCity" />
						</div>
					</div>

					<!-- <div class="transition-text">
										<el-date-picker
											class="transition-texta-a"
											v-model="InsurSearchInfo.StartDate"
											size="medium"
											value-format="yyyy-MM-dd"
											type="date"
											:clearable="false"
											placeholder="YYYY-MM-DD"
											@change="changedate"
										></el-date-picker>									
									</div> -->

					<div class="transition-md">
						<div class="transition-mdaaa">出发日期</div>
						<el-date-picker value-format="yyyy-MM-dd" v-model="dates" size="" class=""
							type="daterange" range-separator="—" start-placeholder="开始日期" end-placeholder="结束日期"
							:clearable="false" :picker-options="pickerOptions0" @change="changedate"></el-date-picker>

						<!-- <el-date-picker
											class="transition-texta-a"
											v-model="InsurSearchInfo.EndDate"
											size="medium"
											value-format="yyyy-MM-dd"
											type="date"
											:clearable="false"
											placeholder="YYYY-MM-DD"
											@change="changedatea"
										></el-date-picker> -->
						<!-- <span class="icon-fontb iconfont icon-rili"></span> -->
					</div>

					<div class="transition-text transition-text-s transition-md">
						<div class="transition-mda">保险公司</div>   <!-- transition-text-bx -->
						<el-select v-model="InsurSearchInfo.GoodOwnerCode" placeholder="全部" size=""
							popper-class="transition-pop" style="width:230px">
							<el-option label="全部" value=""></el-option>
							<el-option v-for="item in InsurOwners" :key="item.GoodOwnerCode" :label="item.OwnerNameCn"
								:value="item.GoodOwnerCode"></el-option>
						</el-select>
						<span class="icon-fontc iconfont icon-jiantouxiamian"></span>
					</div>
					<div class="transition-sousuo-s">
						<a href="javascript:;" @click="bindSearch" class="transition-sousuo">搜索</a>
					</div>
				</div>
				<!-- <el-collapse-transition>
					
						<el-row type="flex" justify="space-between" align="middle" class="transition-box" style=" border:none;padding-top: 28px;">
							<el-row type="flex" >
								
							</el-row>
							
						</el-row>
				</el-collapse-transition> -->
			</div>
		</div>

		<div class="spaceflight mt10" v-show="false">
			<!-- 选后的筛选 -->
			<!-- <div class="sflight">
				<div class="sflight-a">
					<div class="sflight-b">
						<span class="sflight-bb">类型：</span>
						<span class="sflight-bbb">{{ value8 }}</span>
						<span class="sflight-x iconfont icon-chalv-guanbi"></span>
					</div>

					<div class="sflight-c">
						<span class="sflight-cc">保险产品：</span>
						<span class="sflight-ccc">{{ value9 }}</span>
						<span class="sflight-x iconfont icon-chalv-guanbi"></span>
					</div>

					<span class="sflight-q">清空筛选条件</span>
				</div>
			</div> -->

			<div class="popup">
				<!-- 用户筛选 -->
				<el-row class="popup-a">
					<el-col :span="12">
						<el-row type="flex">
							<el-col :span="2" class="popup-s"><span>筛选</span></el-col>

							<el-select v-model="value8" clearable placeholder="类型" popper-class="quanbua"
								class="quanbub">
								<el-option v-for="item in leixing" :key="item.value8" :label="item.label8"
									:value="item.value8"></el-option>
							</el-select>

							<el-select v-model="value9" clearable placeholder="保险产品" popper-class="baoxiana"
								class="baoxianb">
								<el-option v-for="item in baoxian" :key="item.value9" :label="item.label9"
									:value="item.value9"></el-option>
							</el-select>
						</el-row>
					</el-col>

					<el-col :span="12">
						<el-row type="flex" class="row-bg" justify="end">
							<el-col :span="9">
								<div class="popup-m">
									<el-input v-model="KeyWord" placeholder="产品名称" class="popup-i"></el-input>
								</div>
							</el-col>
							<el-col :span="2" class="popup-so"><a href="javascript:;"
									@click="bindSearchByKeyWord">搜索</a></el-col>
						</el-row>
					</el-col>
				</el-row>
			</div>
		</div>

		<yellowLoading v-if="isShowloading"></yellowLoading>
		<flightNoData v-if="isNoData" msg="酒店"></flightNoData>

		<div class="huatai mt10" v-if="InsuranceRates.length > 0">
			<!-- 保险列表 -->
			<div class="huatai-a" v-for="(item, index) in InsuranceRates" :key="index">
				<div class="huatai-ab">
						<div class="huatai-b">
							<div class="huatai-bb">
								<a href="javascript:;" @click="bindItemSelect(item)">
									<img src="../../assets/baoxian10.png" alt="" class="huatai-img" />
									<div class="huatai-bx">{{ item.ProviderName }}</div>
								</a>
							</div>
						</div>
					
						<div class="huatai-c">
							<div class="huatai-ca">
								<div class="huatai-caa">
									<span class="huatai-cb fudong">
										<!-- 点击后跳转链接 -->
										<a href="javascript:;" @click="bindItemSelect(item)">{{ item.ProductName }}</a>
									</span>
									<div class="huatai-cd" v-if="item.IsNetworkSale == 1">直连</div>
								</div>
					
								<p class="huatai-cp">{{ item.EnsureDescribe }}</p>
					
								<p class="huatai-cf">
									<i>
										<i>最大期限：</i>
										<i>{{ item.MaxDays }}天</i>
										<i class="huatai-ci">可当天生效</i>
									</i>
									<i class="huatai-ch">
										已被购买
										<i class="huatai-ck">{{ item.Sale }}</i>
										次
										<!-- <i class="huatai-cl">儿童</i> -->
									</i>
								</p>
							</div>
						</div>
					
						<div class="huatai-d">
							<div class="huatai-da">
								<div class="huatai-dp">
									<div>￥</div>
									<i class="huatai-di">{{ item.SalePrice }}</i>
								</div>
								<!-- <p>价格</p> -->
							</div>
							<div class="huatai-ea">
								<p><a href="javascript:;" @click="bindItemSelect(item)">查看详情</a></p>
							</div>
						</div>
				</div>
			</div>
		</div>

		<div class="aviation-fenye">
			<!-- 分页 -->
			<div class="aviation-fenye-a">
				<!-- <el-pagination
					background
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page.sync="currentPage3"
					:page-size="100"
					layout="prev, pager, next, jumper"
					:total="1000">
				</el-pagination> -->
				<!-- <el-pagination class="aviation-fenye-c" background layout="prev, pager, next" :total="RowCount" :pageSize="pagesize" @handleCurrentChange="handleCurrentChange"></el-pagination> -->
				<pagination @handleCurrentChange="handleCurrentChange" :pageTotal="RowCount" :pageSize="pagesize">
				</pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import yellowLoading from '../../components/yellowLodaing.vue';
	import flightNoData from '../../components/flightNoData.vue';
	import pagination from '../../components/pagination.vue';
	import {
		getInsurSearchInfo,
		setInsurSearchInfo
	} from '@/utils/localdatas';
	import InsuranceCity from '@/components/InsuranceCity/InsuranceCity.vue';
	var util = require('@/utils/util.js');
	var insApi = require('@/api/insApi.js'); //保险接口
	var destApi = require('@/api/destApi.js');
	var that;

	import moment from 'moment';
	var that;

	export default {
		components: {
			yellowLoading,
			flightNoData,
			pagination,
			InsuranceCity
		},
		data() {
			return {
				day: 0,
				show3: false,
				input: '',
				value1: '',
				value2: '',
				value3: '',
				leixing: [{
						value8: '全部',
						label8: '全部'
					},
					{
						value8: '成人',
						label8: '成人'
					},
					{
						value8: '儿童',
						label8: '儿童'
					},
					{
						value8: '老人',
						label8: '老人'
					}
				],
				showCity: '',
				value8: '',
				baoxian: [{
						value9: '华泰“安翔无忧”公共交通工具人身意外伤害保险7天',
						label9: '华泰“安翔无忧”公共交通工具人身意外伤害保险7天'
					},
					{
						value9: '易安航空意外险',
						label9: '易安航空意外险'
					},
					{
						value9: '华泰“安翔无忧”航空意外保险（行程单版）',
						label9: '华泰“安翔无忧”航空意外保险（行程单版）'
					},
					{
						value9: '华泰综合交通工具意外险',
						label9: '华泰综合交通工具意外险'
					}
				],
				value9: '',
				options: [{
						value: '选项1',
						label: '全部'
					},
					{
						value: '选项2',
						label: '优普旅行援助'
					},
					{
						value: '选项3',
						label: '泰康人寿'
					},
					{
						value: '选项4',
						label: '史带财产保险股份有限公司'
					},
					{
						value: '选项5',
						label: '平安保险'
					},
					{
						value: '选项6',
						label: '中国人民财产保险股份有限公司'
					},
					{
						value: '选项7',
						label: '携保保险网'
					}
				],
				value: '',

				isShowloading: true,
				isNoData: false,
				KeyWord: '',
				RowCount: 0,
				pageindex: 0,
				pagesize: 20,
				dates: [],
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				InsurOwners: [],
				InsurSearchInfo: getInsurSearchInfo(),
				InsuranceRates: [], //保险列表
				DestinationCountryVal: ''
			};
		},
		//创建
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);
			this.$router.push({
				path: '/'
			}); 
			that.dates = [that.InsurSearchInfo.StartDate, that.InsurSearchInfo.EndDate];
			getInsuranceRateList();
			getInsurOwners();
			// getInsurSearchInfoJSON(null);
			// that.day = getDaysBetween('2020-11-10', '2020-11-16');
		},
		//方法
		methods: {
			//搜索国家
			queryCitySearch(queryString, cb) {
				var parm = {
					keyword: queryString,
					isCountry: true
				};
				destApi.GetCityList(parm, function(result) {
					// console.log(result);;
					if (result.code == 0) {
						var allCityList = result.data.allCityList;
						cb(allCityList);
						// console.log(that.restaurants);
					} else {
						cb([]);
					}
				});

				// cb(results);
			},
			handleSelectCity(item) {
				console.log(item);
				that.DestinationCountryVal = item.value;
				that.InsurSearchInfo.DestinationCountry = item.code;
			},
			sousuo(e) {
				this.$router.push({
					path: '/Insurance/list'
				});
			},
			tiaochu(e) {
				this.$router.push({
					path: '/Insurance/detail'
				});
			},
			sousuoby(e) {
				this.$router.push({
					path: '/Insurance/list'
				});
			},
			changedate(e) {
				that.InsurSearchInfo.StartDate = that.dates[0];
				that.InsurSearchInfo.EndDate = that.dates[1];
				// that.InsurSearchInfo.StartDate=moment(e).format('YYYY-MM-DD');
				// console.log(that.InsurSearchInfo.StartDate); //打印出开始日期
				var day = getDaysBetween(that.InsurSearchInfo.StartDate, that.InsurSearchInfo.EndDate);
				that.InsurSearchInfo.Days = day; //将计算出来的day天数赋值给后台接口days天数
				setInsurSearchInfo(that.InsurSearchInfo);
				getInsuranceRateList();
				console.log(day);
			},
			changedatea(e) {
				// that.InsurSearchInfo.EndDate=moment(e).format('YYYY-MM-DD');
				console.log(that.InsurSearchInfo.EndDate); //打印出结束日期
				var day = getDaysBetween(that.InsurSearchInfo.StartDate, that.InsurSearchInfo.EndDate);
				that.InsurSearchInfo.Days = day; //将计算出来的day天数赋值给后台接口days天数
				console.log(day);
				// console.log(e);
			},
			//分页
			handleCurrentChange(page) {
				that.isShowLoading = true;
				console.log(`当前页: ${page}`);
				that.pageindex = page - 1;
				getInsuranceRateList();
			},
			bindSearchByKeyWord(e) {
				that.pageindex = 0;
				getInsuranceRateList();
			},
			bindSearch(e) {
				that.pageindex = 0;
				that.InsurSearchInfo.StartDate = that.dates[0];
				that.InsurSearchInfo.EndDate = that.dates[1];
				setInsurSearchInfo(that.InsurSearchInfo);
				getInsuranceRateList();
			},
			//选择
			bindItemSelect(item) {
				// that.$router.push({ path: '/Insurance/list' });
				console.log(item);
				that.$router.push({
					path: '/Insurance/detail',
					query: {
						ProductCode: item.ProductCode,
						PlanCode: item.PlanCode
					}
				});
			}
		}
	};

	function getDaysBetween(dateString1, dateString2) {
		//计算两个日期之间的相差天数
		var startDate = Date.parse(dateString1);
		var endDate = Date.parse(dateString2);
		var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000) + 1;
		return days;
	}

	//获取查询的信息
	function getInsurSearchInfoJSON(parm) {
		insApi.GetInsurSearch(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.InsurSearchInfo = result.data.InsurSearchInfo;
				//获得查询信息之后将查询的信息传入保险列表的接口去查询列表信息
				getInsuranceRateList();
			}
		});
	}
	//获取保险列表
	function getInsuranceRateList() {
		that.InsuranceRates = [];
		that.isShowloading = true;
		console.log(that.InsurSearchInfo);
		var parm = {
			pageindex: that.pageindex,
			KeyWord: that.KeyWord,
			InsurSearchInfoJSON: JSON.stringify(that.InsurSearchInfo)
		};

		insApi.GetInsuranceRateList(parm, function(result) {
			that.isShowloading = false;
			// console.log(result);;
			if (result.code == 0) {
				that.InsuranceRates = result.data.InsuranceRates;
				that.RowCount = result.data.RowCount;
				that.isNoData = that.InsuranceRates.length == 0;
			}
		});
	}

	function getInsurOwners() {
		insApi.GetInsurOwners(null, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.InsurOwners = result.data.InsurOwners;
			} else {}
		});
	}
</script>

<style lang="scss">
	@import '../../style/Insurance/list.scss';
</style>
