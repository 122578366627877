<template>
	<div class="flight-listbox" style="">
		<div class="reserch pd50 text-c">
			<span class="iconfont icon-smile-md icon-chalv-dingdan gray9" style="font-size:40px;"></span>
			<p class="mt20 gray6">很抱歉，没有找到符合条件的{{ msg }}。</p>
			<!--<p class="mt10 gray6">
				你可以：
				<a href="javascript:;" @click="bindReSearch">重新更改筛选条件</a>
			</p>-->
		</div>
	</div>
</template>

<script>
export default {
	name: 'FlightNoData',
	props: {
		msg: String
	},
	data() {
		return {};
	},
	methods: {
		bindReSearch() {
			this.$emit('bindReSearch');
		}
	}
};
</script>

<style></style>
