<template>
	<div :class="'flight-listbox ' +(type=='酒店'?'bggray':'')">
		<div :class="'feihuoLoading ' +(type=='酒店'?'bggray':'')">
			<div class="serch-loading-img">
				<img src="../assets/loading.gif" style="width:140px"/>
			</div>
			<p class="mt20 ft16">正在加载中...</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'yellowLoading',
		props: ['msg','type'],
		data() {
			return {}
		}
	}
</script>

<style>
	.bggray{
		 background: #eee;
	}
</style>
